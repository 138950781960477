import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/callback": [~16],
		"/(authed)/dash": [~5,[2]],
		"/(authed)/dash/cal": [~6,[2]],
		"/(authed)/dash/cal/old": [~7,[2]],
		"/(authed)/dash/mentors": [~11,[2]],
		"/(authed)/dash/mentors/[userId]": [~12,[2]],
		"/(authed)/dash/mentors/[userId]/availability": [~13,[2]],
		"/(authed)/dash/mentors/[userId]/types": [~14,[2]],
		"/(authed)/dash/sessions/[sessionId]": [~10,[2]],
		"/(authed)/dash/types": [~9,[2]],
		"/(authed)/dash/users": [~8,[2]],
		"/(authed)/schedule": [~4],
		"/status": [15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';